import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUtilityService } from './api-utility.service';
import { APIService } from './api.service';

@Injectable()
export class CoreAPIService {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {APIService} api_service
	 */
	constructor(private api_service: APIService, private api_utility_service: APIUtilityService) {}

	/**
	 * Gets dealer.
	 *
	 * @returns {Observable}
	 */
	getDealer(http_params: HttpParams = new HttpParams()): Observable<any> {
		return this.api_service.request('get', 'core/dealer', http_params);
	}

	/**
	 * Gets vehicle manufacturers.
	 *
	 * @returns {Observable}
	 */
	getVehicleManufacturers(): Observable<any> {
		return this.api_service.request('get', 'core/vehicle-manufacturers');
	}

	/**
	 * Gets vehicle ranges from manufacturer.
	 *
	 * @param manufacturer_id
	 *
	 * @returns {Observable}
	 */
	getVehicleRanges(manufacturer_id: number): Observable<any> {
		return this.api_service.request('get', `core/vehicle-manufacturers/${manufacturer_id}/ranges`);
	}

	/**
	 * Gets vehicle models from manufacturer and range.
	 *
	 * @param manufacturer_id
	 * @param range_id
	 *
	 * @returns {Observable}
	 */
	getVehicleModels(manufacturer_id: number, range_id: number): Observable<any> {
		return this.api_service.request(
			'get',
			`core/vehicle-manufacturers/${manufacturer_id}/ranges/${range_id}/models`
		);
	}

	/**
	 * Gets vehicle editions from manufacturer, range and model.
	 *
	 * @param manufacturer_id
	 * @param range_id
	 * @param model_id
	 *
	 * @returns {Observable}
	 */
	getVehicleEditions(manufacturer_id: number, range_id: number, model_id: number): Observable<any> {
		return this.api_service.request(
			'get',
			`core/vehicle-manufacturers/${manufacturer_id}/ranges/${range_id}/models/${model_id}/editions`
		);
	}

	/**
	 * Sends access code to requested medium.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	authenticate(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest('post', 'configure/enquiry/' + enquiry_hash + '/authenticate', body)
		);
	}

	/**
	 * Gets obfuscated customer email and telephone number.
	 *
	 * @param {string} enquiry_hash
	 * @returns {Observable}
	 */
	obfuscate(enquiry_hash: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request('get', 'configure/enquiry/' + enquiry_hash + '/obfuscate')
		);
	}

	/**
	 * Verifies access code is correct, endpoint returns token.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	verify(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest('post', 'configure/enquiry/' + enquiry_hash + '/verify', body),
			null,
			null,
			true,
			true
		);
	}

	/**
	 * Invalidates all tokens for enquiry.
	 *
	 * @param {string} enquiry_hash
	 * @returns {Observable}
	 */
	invalidate(enquiry_hash: string): Observable<any> {
		return this.api_service.postOrPutRequest(
			'post',
			'configure/enquiry/' + enquiry_hash + '/invalidate',
			null,
			null,
			this.api_utility_service.getEnquiryHeaders(enquiry_hash)
		);
	}

	/**
	 * Tracks activity.
	 *
	 * @param {string} enquiry_hash
	 * @param body
	 * @returns {Observable}
	 */
	trackActivity(enquiry_hash: string, body: any): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				'configure/enquiry/' + enquiry_hash + '/tracking',
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/configure/e', enquiry_hash, 'authenticate'],
			true
		);
	}
}
